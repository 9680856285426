import React from "react";
import { Box, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {  PiFilesFill } from "react-icons/pi";
import { MdLiveTv } from "react-icons/md";
import { FaTruck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import bosonLogo from "../../images/boson.png";
import Logout from "../../components/Logout";

const Topbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const colorMode = useContext(ColorModeContext);
  
  return (
    <Box display="flex" justifyContent={"space-between"} p={2}>
      {/* search bar   */}
      {!isMobile && <Box
        display={"flex"}
        backgroundColor={colors.primary[400]}
        borderRadius={"3px"}
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search"></InputBase>
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>}

      <Box display={"flex"}>
        {isMobile && <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="30px"
                  height="40px"
                  src={bosonLogo}  
                  onClick={()=> navigate("/dashboard")}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                  style={{ cursor: "pointer", borderRadius: "50%", marginRight:"7px" }}
                />
        </Box>}
        <Button variant="contained" sx={{display:"flex", alignItems:"center", mr:2,bgcolor: "#f01f34", "&:hover": {bgcolor: colors.redAccent[500]}}} onClick={()=> navigate("/livelocation")}>
          <MdLiveTv size={20} style={{marginRight:"7px"}}/>
          Live Status 
        </Button>
        {isMobile && <Button variant="contained" sx={{display:"flex", alignItems:"center", mr:2,bgcolor: "#24153d", "&:hover": {bgcolor: colors.blueAccent[200]}}} onClick={()=> navigate("/userInfo")}>
          <FaTruck  size={20} style={{marginRight:"7px"}}/>
          Assign Vehicles
        </Button>}
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/fetchFiles")}
        >
          <Box sx={{ display: "flex", justifyContent:"center", alignItems: "center" }}>
            <PiFilesFill size={20} />
            <Box component="span" sx={{ ml: 1,fontStyle:"italic" }}>
              Mission Files
            </Box>
          </Box>
        </Button>

        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {!isMobile && <Logout/>}
        
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default Topbar;