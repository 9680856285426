import { Box, Button, Typography } from '@mui/material';
import { BsConeStriped } from "react-icons/bs";
import React from 'react'
import { toast } from 'react-toastify';
import { TbAlertTriangleFilled } from "react-icons/tb";
import axios from 'axios';

const ObstacleAlert = ({stopLive, setIsObstacle, setIsPatrol, imgData}) => {
  
  const VehicleId = localStorage.getItem("VehicleId"); 
  const endOperation = async()=>{
    try{
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/stopPatrol/${VehicleId}`); 
      setIsObstacle(false);
      setIsPatrol(false);
      stopLive();
      toast.success(res.data);
    }catch(err){
      toast.error(err.response.data); 
    }
  }

  const byPass = async()=>{
    try{
      const res  = await axios.post(`${process.env.REACT_APP_API_URL}/obstaclebypass/${VehicleId}`);
      toast.success(res.data);
    }catch(err){
      toast.error(err.response.data);
    }
  }
  return (
   
    <Box
  sx={{
    height: "auto",
    width: { xs: "350px", sm: "500px" },
    bgcolor: "white",
    p: 5,
    justifyContent: "center",
    borderRadius: 5,
    alignItems: "center",
    border: "2px solid red",
    boxShadow: 2,
  }}
>
  <Typography
    variant="h3"
    sx={{
      color: "red",
      fontFamily: "-moz-initial",
      display: "flex",
      alignItems: "center",
    }}
  >
    <BsConeStriped size={32} />
    Obstacle Detected
  </Typography>
  <Typography
    variant="h6"
    sx={{ fontFamily: "sans-serif", mt: 1 }}
  >
    Obstacle Detected near the Boson Truck, Please review the available
    image to verify that it is safe to resume operations.
  </Typography>
  <Box
    sx={{
      height: { xs: "150px", sm: "250px" },
      width: { xs: "250px", sm: "400px" },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {imgData ? (
      <img
        src={`data:image/jpeg;base64,${imgData}`}
        alt="obstacle"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
        }}
      />
    ) : (
      <Typography
        variant="h3"
        fontFamily="sans-serif"
        mt="2"
        color="red"
        sx={{ textAlign: "center" }}
      >
        Camera Not enabled <TbAlertTriangleFilled size={20} />
      </Typography>
    )}
  </Box>

  <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
    <Button
      onClick={endOperation}
      sx={{
        bgcolor: "red",
        px: { xs: 0, sm: 4 },
        py: { xs: 0, sm: 1 },
        mr: { xs: 2, sm: 0 },
        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "bold",
        color: "white",
        "&:hover": { bgcolor: "red" },
      }}
    >
      End Operation
    </Button>
    <Button
      onClick={byPass}
      sx={{
        bgcolor: "green",
        px: { xs: 0, sm: 3 },
        py: { xs: 0, sm: 1 },
        fontFamily: "sans-serif",
        fontSize: "13px",
        fontWeight: "bold",
        color: "white",
        "&:hover": { bgcolor: "#12ad03" },
      }}
    >
      Bypass and Monitor
    </Button>
  </Box>
</Box>

  )
}

export default ObstacleAlert;