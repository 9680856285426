import {AppBar,Box,Button,Card,CardActionArea,CardContent,CardMedia,Grid,IconButton,List,ListItem,ListItemText,Toolbar,Typography,
  useTheme,
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaXTwitter, FaLinkedinIn } from "react-icons/fa6";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ColorModeContext, tokens } from "../theme";
import bgimg from "../images/bosontruck.png";
import missionFileImg from "../images/geojson.png"; 
import realTimeImg from "../images/realtime.jpg";
import mapViewImg from "../images/mapview.png";

const Home = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const currentYear = new Date().getFullYear(); 
  const assignedVehicles = localStorage.getItem("assignedVehicles"); 
  const navigate = useNavigate(); 

  const handleMissionFileClick = ()=>{
    if(assignedVehicles.length > 0) navigate("/fetchFiles"); 
    else navigate("/"); 
  }

  return (
    <Box sx={{overflowX:"hidden"}}>
      <AppBar
        position="fixed"
        sx={{
          bgcolor:
            theme.palette.mode === "light" ? "black" : colors.primary[600],
          boxShadow: "2",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src="https://bosonmotors.com/wp-content/uploads/2022/06/logo.png"
              alt="boson"
            />
          </Typography>
          <IconButton onClick={colorMode.toggleColorMode} sx={{ mr: 2 , color:"white"}}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
            sx={{ fontSize: {xs:"14px", sm:"16px", md:"20px"}, mr: 2, borderRadius: 2}}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="success"
            component={Link}
            to="/signup"
            sx={{ fontSize: {xs:"14px", sm:"16px", md:"20px"}, borderRadius: 3 }}
          >
            Signup
          </Button>
        </Toolbar>
      </AppBar>

      <Grid
        container
        spacing={2}
        sx={{
          mt: 5,
          minHeight:"88vh",
          px: { xs: 5, sm: 4, md: 8 },
          py: { xs:10, sm: 15, md: 20 },
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          backgroundImage:`url(${bgimg})`, 
          backgroundRepeat:"no-repeat",
          backgroundPosition:"center",
          backgroundSize:"cover"
        }}
      >
        <Grid item xs={10} sm={6.5} md={5} sx={{ color:"white",p:{xs:2, sm:3, md:4}, mb:{xs:5}}}>
          <AnimatePresence>
            <motion.div
              animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
              transition={{
                duration: 5,
                delay: 0.3,
                ease: [0.5, 0.71, 1, 1.5],
              }}
              initial={{ opacity: 0, scale: 0.5 }}
              whileHover={{ scale: 1.2, color:"#f1ffe8" }}
            >
              <Typography
                variant="h2"
                fontWeight="bold"
                sx={{ mb: "5px", color:"#f1ffe8",
                  fontSize: {xs:"1.7rem",sm:"2.5rem",md:"2.8rem"}
                }}
              >
                Next-Gen <Box component="span" sx={{ color: "green" }}>Electric</Box> Autonomous <Box component="span" sx={{color:"green"}}  >Vehicles</Box>
              </Typography>
              <Typography variant="h5"  fontWeight="bold" sx={{ color:"#f1ffe8", fontSize: {xs:"1.7rem",sm:"2.5rem"}}}>
                Revolutionize the Agriculture
              </Typography>
              <Button
                variant="contained"
                color="success"
                sx={{ mt: 5, borderRadius: 2,  }}
                onClick={()=> navigate("/login")}
              >
                Learn More
              </Button>
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Box
          sx={{
            display: "flex",
            p: 3,
            justifyContent: "space-evenly",
            alignItems: "center",
            bgcolor: theme.palette.mode === "light" ? "#e33232" : "#a12b2b",
            color: "white",
            fontStyle: "italic",
          }}
        >
          <motion.div
            whileHover={{ scale: 1.2 }}
            animate={{ x: [50, 150, 50], opacity: 1, scale: 1 }}
            transition={{ duration: 5, delay: 0.3, ease: [0.5, 0.71, 1, 0.2] }}
          >
            <Typography variant="h2" sx={{ fontWeight: "bold"}}>
              Off Road, Off Grid!!!
            </Typography>
          </motion.div>
        </Box>
      </motion.div>

   

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          p: 10,
        }}
      >
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={4}>
            <Card sx={{ maxWidth: 350, borderRadius: 2 }} onClick={() =>
                window.open("https://grafana.bosonmotors.com/login", "_blank")
              }>
              <CardActionArea sx={{"&:hover":{bgcolor:colors.grey[700]}}}>
                <CardMedia
                  component="img"
                  height="200"
                  image={realTimeImg}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    RealTime Vehicle Data
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Datas that can be collected from the vehicle such as vehicle
                    status, Autonomous hours, distance travelled, faults
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={4} >
            <Card sx={{ maxWidth: 350, borderRadius: 2,}} onClick={handleMissionFileClick} >
              <CardActionArea  sx={{"&:hover":{bgcolor:colors.grey[700]}}} >
                <CardMedia
                  component="img"
                  height="200"
                  image={missionFileImg}
                  alt="Mission Files"
                 
                />
                <CardContent >
                  <Typography gutterBottom variant="h5" component="div">
                    Mission Files Management
                  </Typography>
                  <Typography variant="body2" color="text.secondary" >
                    Mission file is a json file which contains the contents like
                    gps-coordinates, odometry, imu. This mission File will be in
                    a json, geojson, osm format.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ maxWidth: 350, borderRadius: 2 }}>
              <CardActionArea sx={{"&:hover":{bgcolor:colors.grey[700]}}}>
                <CardMedia
                  component="img"
                  height="200"
                  image={mapViewImg}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Map View
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Map view will be available for every savepath so that the
                    customers can able to visualise the savepath from this
                    webapp
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Box>


      <Box
      p={8}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.9)",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6} textAlign="center">
          <img
            src="https://bosonmotors.com/wp-content/uploads/2022/06/logo.png"
            alt="Boson Motors"
            style={{ maxWidth: "150px", marginBottom: "16px" }}
          />
          <Typography
            variant="body1"
            color="grey.400"
            fontWeight="normal"
            paragraph
          >
            Revolutionizing agriculture, Boson Motors develops advanced
            autonomous vehicles designed to enhance farming efficiency and
            productivity. Our innovative solutions combine cutting-edge AI
            technology with sustainable practices to support farmers in
            optimizing their operations and increasing yields.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <IconButton
              sx={{ color: "white", "&:hover": { color: "#E4405F" } }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/bosonmotors/",
                  "_blank"
                )
              }
            >
              <FaInstagram />
            </IconButton>
            <IconButton
              sx={{ color: "white", "&:hover": { color: "#25D366" } }}
              onClick={() =>
                window.open("https://wa.me/+916380802804", "_blank")
              }
            >
              <FaWhatsapp />
            </IconButton>
            <IconButton
              sx={{ color: "white", "&:hover": { color: "#0077B5" } }}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/boson-motors-usa/",
                  "_blank"
                )
              }
            >
              <FaLinkedinIn />
            </IconButton>
            <IconButton
              sx={{ color: "white", "&:hover": { color: "#1DA1F2" } }}
              onClick={() =>
                window.open("https://x.com/BosonMotors", "_blank")
              }
            >
              <FaXTwitter />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <List sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
            {[
              "Home",
              "About Us",
              "Contacts",
              "Privacy Policy",
              "Terms of Use",
            ].map((text) => (
              <ListItem
                key={text}
                sx={{
                  cursor: "pointer",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  transition: "background-color 0.3s",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "red",
                  },
                }}
              >
                <ListItemText primary={text} sx={{ textAlign: "center" }} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      <Box
        sx={{
          mt: 4,
          textAlign: "center",
          width: "100%",
          borderTop: "1px solid rgba(255, 255, 255, 0.3)",
          pt: 2,
        }}
      >
        <Typography variant="body1" color="grey.500">
          &copy; {currentYear} Boson Motors All rights reserved.
        </Typography>
      </Box>
    </Box>

    </Box>
  );
};

export default Home;