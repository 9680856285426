import { Box, Button, Typography, Drawer, useTheme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { VscDebugStart } from "react-icons/vsc";
import { FaRegStopCircle } from "react-icons/fa";
import { PiFilesBold } from "react-icons/pi";


const drawerWidth = 280;

// const fileNames =[
//    "path170___123456.json",
//    "mango___56789.json", 
//    "walnut___173456.json",
//    "brentwood___172378.json",
//    "palmFarm___143256.json",
//    "dairy___1764543.json"
// ]

const Patrol = ({goLive, stopLive, missionFiles, isPatrol, setIsPatrol}) => {
  
  const [selectedfileName, setSelectedFileName] = useState("");
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const VehicleId = localStorage.getItem("VehicleId");
 
  const cleanedString = missionFiles.replace(/^"|"$/g, '') 
                              .replace(/\[|\]/g, '')    
                              .replace(/PosixPath\('|'\)/g, '') 
                              .split(', '); 


  const fileNames = cleanedString.map(path => {
    const pathParts = path.split('/');
    return pathParts[pathParts.length - 1];
  });


  const startPatrol = async (filename) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/startPatrol/${VehicleId}/${filename}`);
      setIsPatrol(true);
      setSelectedFileName(filename); 
      goLive(); 
      toast.info(res.data);
      if (isMobile) setMobileOpen(false);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const stopPatrol = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/stopPatrol/${VehicleId}`);
      setIsPatrol(false);
      stopLive(); 
      toast.info(res.data);
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const sidebarContent = (
    <Box sx={{
      height: '100%',
      top:0,
      bgcolor: 'inherit',
      boxShadow: 3,
      overflow: 'auto'
    }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight:"bold",
          fontFamily:"monospace",
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
          bgcolor: 'black',
          color: '#b3c7e8',
        }}
      >
       MISSION FILES
      </Typography>
      
      {fileNames.map((filename, index) => (
        <Box
          key={index}
          sx={{
            p: 2,
            borderBottom: 1,
            borderColor: 'divider',
            color:theme.palette.primary.main,
            bgcolor:"#daceed",
            '&:hover': {
              bgcolor: 'action.hover',
              color: theme.palette.mode === "dark"? 'white': 'black'
            }
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 1,
              fontFamily:"sans-serif",
              fontWeight: 'medium',
              wordBreak: 'break-word'
            }}
          >
           {filename.split("___")[0]}
          </Typography>
          <Button
            onClick={() => startPatrol(filename)}
            variant="contained"
            startIcon={<VscDebugStart />}
            sx={{
              bgcolor: "#432a63",
              py:2,
              fontWeight: "bold",
              borderRadius:5,
              fontFamily:"sans-serif",
              '&:hover': {
                bgcolor: "#410987",
              }
            }}
          >
            Start Mission
          </Button>
        </Box>
      ))}
    </Box>
  );

  const mainContent = (
    <Box sx={{ flexGrow: 1, p: 2, mt:4 }}>
      {isPatrol && (
        <Box
          sx={{
            bgcolor: '#f2a7a7',
            p: 3,
            borderRadius: 2,
            maxWidth: 600,
            mx: 'auto'
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 3,
              color: '#15497d',
              fontFamily:'sans-serif',
              fontWeight:"medium"
            }}
          >
            {selectedfileName.split("___")[0]}
          </Typography>
          <Button
            onClick={stopPatrol}
            variant="contained"
            startIcon={<FaRegStopCircle />}
            sx={{
              bgcolor: "error.main",
              fontFamily:"sans-serif",
              py:2,
              borderRadius:2,
              '&:hover': {
                bgcolor: "error.dark"
              }
            }}
          >
            Stop Mission
          </Button>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {isMobile && (
        <Button
          variant="contained"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ m:2, bgcolor:"yellow", color:"black", display: { md: 'none' } }}
        >
          <PiFilesBold size={20} /> 
        </Button>
      )}

     <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            mt:20,
            boxSizing: 'border-box',
          },
        }}
      >
        {(!isMobile && !isPatrol ) ? sidebarContent : mainContent} 
        {isMobile && sidebarContent}
      </Drawer>
        {isMobile && mainContent}
    </Box>
  );
};

export default Patrol;


