import React from 'react';
import { Box, Typography, Paper, Grid, Chip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import BatteryGauge from 'react-battery-gauge';
import { IoMdClose } from "react-icons/io";
import bosonTruckPreview from "../images/boson_truck_preview.png";


const HmiBridge = ({ hmibridge, toggleHmi, battery_value }) => {
  const VehicleId = localStorage.getItem("VehicleId");
  const VehicleName = JSON.parse(localStorage.getItem("vehicle")).label;

  const StatusChip = ({ active, label }) => (
    <Chip
      label={label}
      sx={{
        width: '100%',
        height: '50px',
        fontSize: "16px",
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: active ? '#2e7d32' : '#d32f2f',
        '&:hover': {
          backgroundColor: active ? '#1b5e20' : '#c62828',
        },
        mb: 1
      }}
    />
  );

  return (
    <Paper
      elevation={3}
      sx={{
        mt: 10,
        p: 3,
        borderRadius: 2,
        background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)',
      }}
    >
      {/* Header Section */}
      <Grid container spacing={2} alignItems="center" mb={3}>
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 'bold',
                color: '#1a237e',
              }}
            >
              {VehicleName}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#555',
                backgroundColor: '#f5f5f5',
                padding: '4px 12px',
                borderRadius: '8px',
              }}
            >
              {VehicleId}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BatteryGauge value={battery_value} size={100} animated={true} padding={3} />
          <IoMdClose size={30} onClick={toggleHmi} />
        </Grid>

      </Grid>

      {/* Status Section */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography variant="h4" fontStyle={"italic"} fontWeight="bold">
              MISSION COMPLETION
            </Typography>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* Background grey circle */}
              <CircularProgress
                variant="determinate"
                size={80}
                value={100}
                sx={{
                  color: 'grey.200',
                  position: 'absolute'
                }}
              />
              {/* Main progress circle */}
              <CircularProgress
                variant="determinate"
                size={80}
                color="secondary"
                value={parseFloat(hmibridge.path_percentage).toFixed(2)}
              />
              {/* Percentage text */}
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body1" component="div">
                  {`${hmibridge.path_percentage}%`}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            label={hmibridge.patrol ? "Autonomous" : "Manual"}
            sx={{
              width: '100%',
              height: '50px',
              fontSize: '1.25rem',
              fontWeight: 'bold',
              color: 'white',
              backgroundColor: hmibridge.patrol ? '#7e9129' : '#1e4685',
              '&:hover': {
                backgroundColor: hmibridge.patrol ? '#5c6b1d' : '#153156',
              },
            }}
          />
        </Grid>
      </Grid>

      {/* Vehicle Image */}
      <Box
        sx={{
          mb: 3,
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 2,
        }}
      >
        <img
          src={bosonTruckPreview}
          alt="Boson Vehicle"
          style={{
            height: '500px',
            width: '80%',
            objectFit: 'cover',
          }}
        />
      </Box>

      {/* Status Indicators */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StatusChip active={hmibridge.follow_me} label="PERSON FOLLOW" />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatusChip active={hmibridge.is_inside_geofence} label="INSIDE GEOFENCE" />
        </Grid>
      </Grid>

      {/* Error Message */}
      {/* {hmibridge.error && (
        <Box
          sx={{
            mt: 2,
            p: 2,
            backgroundColor: '#ffebee',
            borderRadius: 1,
            border: '1px solid #ef5350',
          }}
        >
          <Typography color="error">{hmibridge.error}</Typography>
        </Box>
      )} */}
    </Paper>
  );
};

export default HmiBridge;