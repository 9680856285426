import { Avatar, Box, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { tokens } from '../theme';
import { toast } from 'react-toastify';
import { CiLogout } from "react-icons/ci";

const Logout = () => {
    const token = localStorage.getItem("token");
    const decoded = jwtDecode(token);
    const { email} = decoded;
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleAvatarClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = ()=>{
      setAnchorEl(null);
    }
    const handleLogout = () => {
      localStorage.clear();
      toast.success("Logged out successfully!!");
      navigate("/");
    };  
  return (
    <Box>
       <Avatar sx={{ bgcolor: colors.redAccent[500] }} onClick={handleAvatarClick}> {email[0].toUpperCase()} </Avatar> 
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={handleLogout}>
          <Typography variant="body1" color="text.primary" sx={{display:"flex",gap:2}}>
            Logout <CiLogout size={20}/>
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default Logout;