import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Home from "./components/Home";
import Loading from "./components/Loading";
import CreateMap from "./scenes/Map/CreateMap";
import DisplayMap from "./scenes/Map/DisplayMap";
import LiveVehicleLocation from "./scenes/Map/LiveVehiclelocation";
import { FileProvider } from "./contexts/FileContext";
import VehicleDashboard from "./scenes/dashboard/VehicleDashboard";
import OSMDataLayer from "./scenes/Map/OSMDataLayer";
import ProtectedRoutes from "./auth/ProtectedRoutes";
import Patrol from "./components/Patrol";


const User = lazy(() => import("./scenes/user"));
const Team = lazy(() => import("./scenes/team"));
const Form = lazy(() => import("./scenes/form"));
const Calendar = lazy(() => import("./scenes/calendar"));
const FAQ = lazy(() => import("./scenes/faq"));
const Bar = lazy(() => import("./scenes/bar"));
const Pie = lazy(() => import("./scenes/pie"));
const Line = lazy(() => import("./scenes/line"));
const Geography = lazy(() => import("./scenes/geography"));
const Upload = lazy(() => import("./scenes/Files/Upload"));
const DisplayFiles = lazy(() => import("./scenes/Files/DisplayFiles"));
const SignupForm = lazy(() => import("./auth/SignupForm"));
const Login = lazy(() => import("./auth/Login"));
const Grafana = lazy(() => import("./scenes/Files/Grafana"));

const roles = {
  1: "admin",
  2: "Boson Engineer",
  3: "Customer",
  4: "Dealer",
};

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <FileProvider>
          <div className="App">
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/signup" element={<SignupForm />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/patrol" element={<Patrol/>}/>
                  <Route path="/fetchFiles" element={<DisplayFiles />} />
                  <Route path="/createmap" element={<CreateMap />} />
                  <Route path="/livelocation" element={<LiveVehicleLocation />} />
                  <Route path="/grafanaDashboard" element={<Grafana />} />
                  <Route path="/displaymap" element={<DisplayMap />} />
                  <Route path="/osmmap" element={<OSMDataLayer />} />
                  <Route path="/upload" element={<Upload />} />

                  <Route element={<ProtectedRoutes requiredRole={roles[1]} />}>
                    <Route path="/dashboard" element={<VehicleDashboard />} />
                    <Route path="/userInfo" element={<User />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/geography" element={<Geography />} />
                  </Route>
                </Routes>
              </Suspense>
             
              <ToastContainer />
            </BrowserRouter>
          </div>
        </FileProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
