import {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import { format, isToday, isYesterday } from "date-fns";
import axios from "axios";
import { toast } from "react-toastify";

let Vehicles = [];

let vehicles = [];

const FileContext = createContext();

export const FileProvider = ({ children }) => {
  const [fileData, setFileData] = useState({});
  const [folderId, setFolderId] = useState("");
  const [vehicle, setVehicle] = useState(null);
  const [assignedVehicles, setAssignedVehicles] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [allVehicles, setAllVehicles] = useState([]);
  const [categorizeVehicles, setCategorizeVehicles] = useState({
    "India Vehicles": [],
    "USA Vehicles": [],
  });

  const [vehicleStatus, setVehicleStatus] = useState({
    "Last Online Time": "",
    "Online Status": "",
  });
  const formatTime = (filename) => {
    try {
      const timestampStr = filename.split('.')[0].split('___')[1];
      const timestamp = parseInt(timestampStr);
      
      if (isNaN(timestamp)) return null;
      
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).format(date);
    } catch (error) {
      return null;
    }
  };
  const formatLastOnlineTime = (nanoseconds) => {
    const milliseconds = nanoseconds / 1e6;
    const date = new Date(milliseconds);
  
    if (isToday(date)) {
      return `Today at ${format(date, "p")}`; // p formats time
    } else if (isYesterday(date)) {
      return `Yesterday at ${format(date, "p")}`;
    } else {
      return format(date, "PPpp"); // PPpp formats date and time
    }
  };

  const Login = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        data
      );
      const { token, vehicleNames } = response.data;
      vehicles = Vehicles.filter((item) => vehicleNames.includes(item.label));
      setIsLoggedIn(true);
      setAssignedVehicles(vehicles);
      localStorage.setItem("token", token);
      localStorage.setItem("assignedVehicles", JSON.stringify(vehicles));
      toast.success("Login successfully");
    } catch (err) {
      toast.error(err.response.data);
    }
  };

  const fetchVehicleStatusAndFiles = useCallback(async (folderId, vehicle) => {
    try {
      setSpinner(true);
      console.log(folderId);
      console.log(vehicle);
      if (folderId !== "ecsvi266337bb4aa34521a1f77ee3e332c980") {
        const vehicleStats = await axios.get(
          `${process.env.REACT_APP_API_URL}/status/${vehicle.label}`
        );
        const {
          "Last Online Time": lastOnlineTime,
          "Online Status": onlineStatus,
          VehicleID,
        } = vehicleStats.data[0];

        setVehicleStatus({
          VehicleId: VehicleID,
          "Last Online Time": formatLastOnlineTime(lastOnlineTime),
          "Online Status": onlineStatus,
        });
        localStorage.setItem("VehicleId", VehicleID);
        localStorage.setItem(
          "lastOnlineTime",
          formatLastOnlineTime(lastOnlineTime)
        );
        localStorage.setItem("onlineStatus", onlineStatus);
      }
      else if (folderId === "ecsvi266337bb4aa34521a1f77ee3e332c980") {
        localStorage.setItem("VehicleId", "MD9DMUE25DC342003");
      }

      const fileDataResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/teamfolders50/${folderId}/files`
      );
      const data = fileDataResponse.data;
      console.log(data);
      setFileData(data);
      setFolderId(folderId);
      setVehicle(vehicle);

      localStorage.setItem("fileData", JSON.stringify(data));
      localStorage.setItem("folderId", folderId);
      localStorage.setItem("vehicle", JSON.stringify(vehicle));
      setSpinner(false);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleSelection = async (event, value) => {
    if (value) {
      event.preventDefault();
      await fetchVehicleStatusAndFiles(value.id, value);
    }
  };

  useEffect(() => {
    const vehicleDetails = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/teamfolders/awdq6d088595db65c4c1a998ea9f848830ec3/subfolders`
        );
        res.data.forEach((item) => {
          if (!item.label.includes("Backup")) {
            Vehicles.push(item);
          }
        });
        const demoitem = {
          id: "ecsvi266337bb4aa34521a1f77ee3e332c980",
          label: "demo",
        };
        Vehicles.push(demoitem);
        setAllVehicles(Vehicles);
        // console.log(Vehicles);
      } catch (err) {
        console.log(err);
      }
    };
    vehicleDetails();
  }, []);

  return (
    <FileContext.Provider
      value={{
        fileData,
        folderId,
        vehicle,
        spinner,
        vehicleStatus,
        assignedVehicles,
        isLoggedIn,
        categorizeVehicles,
        allVehicles,
        Login,
        setFileData,
        setFolderId,
        setVehicle,
        setSpinner,
        setVehicleStatus,
        setCategorizeVehicles,
        handleSelection,
        setAllVehicles,
        fetchVehicleStatusAndFiles,
        formatTime
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export const useFile = () => {
  return useContext(FileContext);
};
